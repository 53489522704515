<template>
  <span>
    <!-- {{ current_v_model }} = {{ getter_current_check_value }} -->
    <!-- $emit('on_change_check_object', current_v_model); -->
    <b-form-radio-group
      v-model="current_v_model"
      @change="
        () => {
          action_setter_current_check_value(current_v_model);
        }
      "
    >
      <b-form-radio
        v-for="(option, index) in options_check"
        :key="index"
        name="departement_adresse_residence"
        :value="option.value"
        class="inline"
        :class="'custom-control-' + option.variant"
      >
        <i class="las" :class="option.icon" />
      </b-form-radio>
    </b-form-radio-group>
  </span>
</template>

<script>
import {
  BRow,
  BCol,
  BPagination,
  VBTooltip,
  BFormSelect,
  BFormRadioGroup,
  BFormRadio,
} from "bootstrap-vue";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import paramsStoreModule from "@/store/params";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  components: {
    BRow,
    BCol,
    BPagination,
    VBTooltip,
    BFormSelect,
    BFormRadioGroup,
    BFormRadio,
  },
  props: {
    // model: {
    //   type: String,
    //   default: () => [],
    // },
    model: {
      type: [Boolean, String],
      default: null,
    },
  },
  setup() {
    const requiredStoreModules = [
      { path: "params", module: paramsStoreModule },
    ];
    registerStoreModule(requiredStoreModules);
  },
  mounted() {
    this.current_v_model = this.model;
    // this.action_setter_current_check_value(this.model);
  },
  data() {
    return {
      options_check: [
        {
          title: "Bon",
          value: true,
          variant: "primary",
          icon: "la-check",
        },
        {
          title: "Pas bon",
          value: false,
          variant: "danger",
          icon: "la-times",
        },
        {
          title: "À réviser",
          value: "to_be_confirmed",
          variant: "secondary",
          icon: "la-question",
        },
      ],
      current_v_model: null,
    };
  },
  computed: {
    ...mapGetters("params", {
      getter_current_check_value: "getter_current_check_value",
    }),
    // -------------------------------------------------------
    tableDataLength() {
      return (this.tableData && this.tableData.length) || 0;
    },
    fromToOver() {
      let from = this.currentPage * this.perPageLocal - this.perPageLocal;
      if (from < 0) from = 0;

      let to = from + this.perPageLocal;
      if (to > this.totalRows) to = this.totalRows;

      if (from === 0) from = 1;
      if (to === 0) from = 0;
      return { from, to };
    },
  },
  watch: {},
  methods: {
    ...mapMutations("params", {
      action_setter_current_check_value: "setter_current_check_value",
    }),
    // --------------------------------------------------
  },
};
</script>

<style scoped lang="scss">
@import "@core/scss/base/bootstrap-extended/include";

ul.pagination.b-pagination {
  margin-bottom: 0;
}
.pagination-ctn {
  @media (max-width: 768px) {
    justify-content: center !important;
    gap: 10px;
    flex-wrap: wrap;
  }
}

.pagination {
  @media (max-width: 768px) {
    justify-content: center !important;
    width: 100%;
  }
}
</style>
